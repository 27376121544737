window.onload = function() {
    (function(d) {
        var
            ce = function(e, n) {
                var a = document.createEvent("CustomEvent");
                a.initCustomEvent(n, true, true, e.target);
                e.target.dispatchEvent(a);
                a = null;
                return false
            },
            nm = true,
            sp = {
                x: 0,
                y: 0
            },
            ep = {
                x: 0,
                y: 0
            },
            touch = {
                touchstart: function(e) {
                    sp = {
                        x: e.touches[0].pageX,
                        y: e.touches[0].pageY
                    }
                },
                touchmove: function(e) {
                    nm = false;
                    ep = {
                        x: e.touches[0].pageX,
                        y: e.touches[0].pageY
                    }
                },
                touchend: function(e) {
                    if (nm) {
                        ce(e, 'fc')
                    } else {
                        var x = ep.x - sp.x,
                            xr = Math.abs(x),
                            y = ep.y - sp.y,
                            yr = Math.abs(y);
                        if (Math.max(xr, yr) > 80) {
                            ce(e, (xr > yr ? (x < 0 ? 'swl' : 'swr') : (y < 0 ? 'swu' : 'swd')))
                        }
                    };
                    nm = true;

                },
                touchcancel: function(e) {
                    nm = false;

                }
            };
        for (var a in touch) {
            d.addEventListener(a, touch[a], false);
        }
    })(document);

}

$(document).ready(function() {

    function arrowActiveOwl(box) {

        if (box.find(".carousel__indicators .active").next().length == 0) {
            box.find(".owl-carousel__btn--right").addClass("noactive");
        } else {
            box.find(".owl-carousel__btn--right").removeClass("noactive");
        }
        if (box.find(".carousel__indicators .active").prev().length == 0) {
            box.find(".owl-carousel__btn--left").addClass("noactive");
        } else {
            box.find(".owl-carousel__btn--left").removeClass("noactive");
        }
    }

    //karuzela produktów
    function owlLoop(container) {

        let row = container.find(".owl-carousel__row");

        let margin = container.find(".owl-carousel__item").outerWidth();
        let marginRow = container.find(".owl-carousel__row").outerWidth();
        let owlItems = container.children(".owl-carousel__item");
        let owlItemsCount = container.find(".owl-carousel__item").length;
        let owlCount = parseInt((marginRow + 20) / margin);
        let owlSlide = Math.ceil(owlItemsCount / owlCount);
        console.log(marginRow);


        let owlDataIteration = 1;

        container.find(".owl-carousel__item").each(function(i) {
            if (i % owlCount == 0 && i != 0) {
                owlDataIteration++;
            }
            $(this).attr("data-owl", owlDataIteration)

        })

        console.log(owlDataIteration);
        if (owlDataIteration <= 1) {
            container.find(".owl-carousel__btn--left").css("opacity", "0");
            container.find(".owl-carousel__btn--right").css("opacity", "0");
        } else {
            container.find(".owl-carousel__btn--left").css("opacity", "0.3");
            container.find(".owl-carousel__btn--right").css("opacity", "0.3");
        }

        for (let i = 0; i < owlSlide; i++) {

            container.find(".carousel__indicators ul").append("<li data-owl=" + i + "></li>");
        }

        if (container.find(".carousel__indicators li").length == 1) {
            container.find(".carousel__indicators").addClass("novisible");
            console.log(container);
            container.find(".owl-carousel__row").css("justify-content", "center");
            return;
        }

        container.find(".carousel__indicators ul li:first-child").addClass("active");


        container.find(".owl-carousel__btn ").click(function(e) {
            e.stopPropagation();
            let owlNumber = container.find(".carousel__indicators .active").data("owl");

            if ($(this).hasClass("owl-carousel__btn--right")) {
                if (container.find(".carousel__indicators .active").next().length) {
                    let owlMarginAll = (owlNumber + 1) * marginRow;
                    container.find(".owl-carousel__item:first-child").css("margin-left", -owlMarginAll);
                    container.find(".carousel__indicators .active").removeClass("active").next().addClass("active");

                }

            } else {

                if (container.find(".carousel__indicators .active").prev().length) {
                    let owlMarginAll2 = (owlNumber - 1) * marginRow;
                    container.find(".owl-carousel__item:first-child").css("margin-left", -owlMarginAll2);
                    container.find(".carousel__indicators .active").removeClass("active").prev().addClass("active");
                }

            }
            arrowActiveOwl(container);
            // if (!container.find(".carousel__stop").hasClass("isactive")) { container.find(".carousel__stop").click(); }
        })
        console.log("ddd");
        container[0].addEventListener('swr', function(e) {
            e.preventDefault();
            console.log("1xxxx");
            container.find(".owl-carousel__btn--left").click();
        })
        container[0].addEventListener('swl', function(e) {
            e.preventDefault();
            console.log("2xxxx");
            container.find(".owl-carousel__btn--right").click();
        })


        // container.find(".carousel__indicators li:first-child").addClass("carousel__stop");
        container.find(".carousel__indicators li").click(function() {
            let owlNumber = $(this).data("owl");
            let owlMarginAll = owlNumber * marginRow;
            container.find(".carousel__indicators .active").removeClass("active");
            $(this).addClass("active");
            container.find(".owl-carousel__item:first-child").css("margin-left", -owlMarginAll);
            console.log("aa");
            arrowActiveOwl(container);
        })
        arrowActiveOwl(container);
    }


    console.log("pppp");
    $(".owl-carousel__wrapper").each(function() {
        console.log("testxxxxxx");
        owlLoop($(this));
    })

    $(".tab__container--owl .owl-carousel--loop").each(function() {
        owlLoop($(this));
    })

    function getProductFromAjax(wrapper, data) {

        if (Array.isArray(data)) {
            let products = data;
            wrapper.prepend('<div class="owl-carousel--loop"><button class="owl-carousel__btn owl-carousel__btn--left"></button><div class="row row--5 owl-carousel__row"></div><div class="carousel__indicators"><ul></ul></div><button class="owl-carousel__btn owl-carousel__btn--right"></button></div>');

            $.each(products, function(i, product) {
                let promo1 = "";
                let promo2 = "";
                let promo3 = "";
                let promoPrice = "";
                let promoClass = "";
                let magazyn = "";
                let promoRight = "";
                let promoLeft = "";
                if (product.etykieta_1) {

                    promo1 = '<span class="info--pink">' + product.etykieta_1 + '</span>';
                    if (product.cenaPoprzednia) {
                        promoPrice = '<span class="product__price--old">' + (product.cenaPoprzednia).replace(".", ",") + ' zł</span>';
                        promoClass = "product__price--sale"
                    }
                }
                if (product.etykieta_2) {
                    promo2 = '<span class="info--blue">' + product.etykieta_2 + '</span>';
                }
                if (product.etykieta_3) {
                    promo3 = '<span class="info--green">' + product.etykieta_3 + '</span>';
                }
                if (product.etykieta_1 || product.etykieta_2 || product.etykieta_3) {
                    promoLeft = '<div class="info__box">'
                    promoRight = '</div>'
                }


                let ulubione = "";
                if (product.lista_zakupowa == 1) {
                    ulubione = ' is--active '
                }

                if (product.magazyn == "0") {
                    magazyn = '<span class="text--main bold">Chwilowo niedostępny</span><button class="btn--black btn--getInfo btn--light btn-modal-js" data-modal="dialog-powiadom">Powiadom</button><button class="constant-cart ' + ulubione + ' "></button></div>'

                } else {
                    magazyn = '<div class="btn--form"><input type="number" value="1"><button>Do koszyka</button></div><button class="constant-cart ' + ulubione + ' "></button></div>'
                }




                let element = $('<div class="col-6 col-md-3 col-xl-2 owl-carousel__item"><div class="product__item product__link product__parent" data-id=' + product.id + '>' + promoLeft + promo1 + promo2 + promo3 + promoRight + '<figure class="product__figure"><img  src="' + product.zdjecie + '" alt="' + product.nazwa + '"></figure><h3><a href="' + product.url + '" title="' + product.nazwa + '">' + product.nazwa + '</a></h3><div class="product__price-box"><span class="product__price ' + promoClass + ' ">' + (product.cena).replace(".", ",") + ' zł</span>' + promoPrice + '</div> <div class="product__btn-box">' + magazyn + '</div></div>')

                wrapper.find(".owl-carousel__row").append(element);
            })

            owlLoop(wrapper.find('.owl-carousel--loop'));
            wrapper.find('.owl-carousel--loop')[0].addEventListener('swl', function(e) {
                e.preventDefault();
                e.stopPropagation();
                if ($(this).find(".carousel__indicators li.active").next().length) {
                    $(this).find(".carousel__indicators li.active").next().click();
                    arrowActiveOwl(wrapper.find('.owl-carousel--loop'));
                }
            }, false)
            wrapper.find('.owl-carousel--loop')[0].addEventListener('swr', function(e) {
                e.preventDefault();
                e.stopPropagation();
                if (!$(this).find(".carousel__indicators li.active").prev().hasClass("carousel__stop")) {
                    $(this).find(".carousel__indicators li.active").prev().click();
                    arrowActiveOwl(wrapper.find('.owl-carousel--loop'));
                }
            }, false)

        } else {
            if (data != "") {
                wrapper.html('<div class="owl__row flex-center"><span class="info-1">' + data + '</span></div>');
            }
        }
    }

    function addTabOwl(tabContainer) {
        tabContainer.find(".products__category--ajax button").click(function(e) {
            e.preventDefault();
            e.stopPropagation();
            if ($(this).hasClass("js--adElements")) return;
            let productId = $(this).data("id");
            let productKind = $(this).data("rodzaj");
            let productHref = "#" + $(this).attr("aria-controls");

            $.get('/a/', {
                    a: "produkty",
                    kategoria: productId,
                    rodzaj: productKind
                },
                function(data) {
                    getProductFromAjax($(productHref), data);
                })
            $(this).addClass("js--adElements");
        })

    }
    $(".tab__container--owl").each(function() {
        addTabOwl($(this));
    })

    let firstScrollToAddElements = 0;
    let firstScrollToAddElementsTabs = 0;

    window.addEventListener("scroll", function(e) {

        if ($(".products__container--owl").length) {

            if (firstScrollToAddElements == 0) {

                if (window.pageYOffset > 100) {
                    firstScrollToAddElements++;
                    $(".products__container--owl").each(function() {
                        let productId = $(this).data("id");
                        let action = $(this).data("action");
                        let category = $(this).data("kategoria");
                        let $this = $(this);
                        $.get('/a/', {
                                a: action,
                                produkt: productId,
                                kategoria: category,
                            },
                            function(data) {

                                getProductFromAjax($this, data);

                                if (!Array.isArray(data) || !data.length) {
                                    $this.parents("section").remove();
                                }

                            })
                    })
                }
            } else {
                return
            }
        }
        if ($(".js--adElements--first").length) {

            if (firstScrollToAddElementsTabs == 0) {
                if (window.pageYOffset > 100) {
                    firstScrollToAddElements++;
                    $(".js--adElements--first").click();
                }
            }
        }
    });
    //kazruzela z markami

    function owl(container) {

        let row = container.find(".owl__row");

        function owlRight() {
            let margin = container.find(".owl__item").outerWidth();
            let item = container.find(".owl__item:first-child").clone();


            row.append(item);
            row.find(".owl__item:last-child img").each(function() {
                if (typeof $(this).data('src') !== 'undefined') {
                    $(this).attr("src", $(this).data("src"));
                }
            })

            container.find(".owl__item:first-child").css("margin-left", -margin);
            setTimeout(function() {
                container.find(".owl__item[style]").remove();
            }, 300);
        }

        container.find(".owl__control").click(function(e) {

            e.stopPropagation();

            if ($(this).hasClass("owl__control--right")) {
                // clearInterval(owlInterval);
                owlRight();


            } else {
                //clearInterval(owlInterval);
                let margin = container.find(".owl__item").outerWidth();
                let item = container.find(".owl__item:last-child").clone();
                row.prepend(item);
                container.find(".owl__item:first-child").css("margin-left", -margin);

                setTimeout(function() {
                    container.find(".owl__item:first-child").css("margin-left", 0);
                    container.find(".owl__item:last-child").remove();
                }, 300);
            }

        })

        container[0].addEventListener('swr', function(e) {
            e.preventDefault();
            container.find(".owl__control--left").click();
        })
        container[0].addEventListener('swl', function(e) {
            e.preventDefault();
            container.find(".owl__control--right").click();
        })

        // let owlInterval = setInterval(() => {
        //     owlRight();
        // }, 2000);
    }


    $(".marks .owl__container").each(function() {
        owl($(this));
        let $this = $(this);
    })


    //karuzela główna


    let slideTimer = 8000; //jesli zmieniam to muszę zmienic transition dla paska na głównym sliderze



    // opóźnienie ładowania zdjęć dla slajdera
    if ($("#mainCarousel")) {
        setTimeout(function() {
            $("#mainCarousel img[data-image]").each(function() {
                $(this).attr("src", $(this).data("image"));
            })
        }, 6000)
    }

    function changeCaruselItem(inner, nr) {
        let slideNr = nr;

        inner.find(".carousel__item.active").removeClass("active");
        inner.find(".carousel__item[data-carousel=" + slideNr + "]").addClass("active");


        inner.parents(".carousel").find(".carousel__nav button.active").removeClass("active");
        inner.parents(".carousel").find(".carousel__nav button[data-carousel=" + slideNr + "]").addClass("active");


        let itemFirstChild = inner.find(".carousel__item:first-child");


        let activeItem = inner.parents(".carousel").find($(".carousel__nav .active"));
        let nrItemBefore = activeItem.parents("li").prevAll().length - 1;

        let itemWidth = itemFirstChild.width() * nrItemBefore;

        itemFirstChild.css("margin-left", -itemWidth);



        inner.parents(".carousel").find(".carousel__timer span").removeClass("active");
        setTimeout(function() {
            inner.parents(".carousel").find(".carousel__timer span").addClass("active");
        }, 100);


        inner.parents(".carousel").find(".carousel__indicators .active").removeClass("active");
        inner.parents(".carousel").find(".carousel__indicators [data-carousel=" + slideNr + "]").addClass("active");
        let buttonActiveWidth = inner.parents(".carousel").find(".button-active").width() * (slideNr - 1);

        inner.parents(".carousel").find(".button-active").css("left", buttonActiveWidth);


        setTimeout(function() {
            inner.parents(".carousel").find(".carousel__timer span").css("width", "100%");
        }, 100);
    }

    function carousel(carousel) {


        carousel.find(".carousel__timer span").css("width", "100%");
        let carouselInner = carousel.find(".carousel__inner");
        let slideNr = carousel.find(".carousel__item").length;
        let buttonActiveLength = carousel.find(".carousel__nav .container").width() / slideNr;

        carousel.find(".button-active").css("width", buttonActiveLength);
        carousel.find(".carousel__nav li").css("max-width", buttonActiveLength);


        carousel.find(".carousel__nav button").click(function(e) {
            e.stopPropagation();
            let activeNr = $(this).data("carousel");
            changeCaruselItem(carouselInner, activeNr);

            if (!carousel.find(".carousel__stop").hasClass("isactive")) {
                carousel.find(".carousel__stop").click();
            }
        })

        carousel.find(".carousel__indicators li:not(:first-child)").click(function(e) {
            e.stopPropagation();
            let activeNr = $(this).data("carousel");
            carousel.find(".carousel__nav [data-carousel=" + activeNr + "]").click();
        })

        carousel[0].addEventListener('swl', function(e) {
            e.preventDefault();
            e.stopPropagation();

            if (carousel.find(".carousel__stop").hasClass("isactive")) {} else { carousel.find(".carousel__stop").click(); }
            if (carousel.find(".carousel__nav button.active").parents().next().length - 1) {
                carousel.find(".carousel__nav button.active").parents().next().find("button").click();
            }
        }, false);
        carousel[0].addEventListener('swr', function(e) {
            e.preventDefault();
            e.stopPropagation();
            if (carousel.find(".carousel__stop").hasClass("isactive")) {} else { carousel.find(".carousel__stop").click(); }
            if (carousel.find(".carousel__nav button.active").parents().prev().length - 2) {
                carousel.find(".carousel__nav button.active").parent().prev().find("button").click();

            }
        }, false);

        function sliderInterval() {

            if (carousel.find(".carousel__nav button.active").parents().next().length - 1) {

                carousel.find(".carousel__nav button.active").parents().next().find("button").click();
            } else {
                carousel.find(".carousel__nav li:nth-child(2)").find("button").click();
            }
        }


        let carouselInterval = setInterval(() => {
            sliderInterval();
        }, slideTimer);

        let intervalActive = 1;

        carousel.find(".carousel__stop").click(function(e) {
            e.stopPropagation();
            if (intervalActive == 1) {
                $(this).addClass("isactive");
                clearInterval(carouselInterval);
                intervalActive = 0;

            } else {
                $(this).removeClass("isactive");
                carouselInterval = setInterval(() => {
                    sliderInterval()
                }, slideTimer);
                intervalActive = 1;
                carousel.parents().find(".carousel__timer span").css("width", "100%");
            }
        })
    }
    $(".carousel").each(function() {
        carousel($(this));
    })

})